import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import './DatasheetView.css';

function DatasheetView() {
  const [scenarios, setScenarios] = useState([]);
  const [newScenario, setNewScenario] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchScenarios();
  }, []);

  async function fetchScenarios() {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .select('*')
        .order('scenarioid', { ascending: false })
        .order('order', { ascending: true });

      if (error) throw error;
      setScenarios(data);
    } catch (error) {
      console.error('Error fetching scenarios:', error.message);
      setError(`Failed to fetch scenarios: ${error.message}`);
    }
  }

  function handleInputChange(e, id, field) {
    const { value } = e.target;
    if (id === 'new') {
      setNewScenario(prev => ({ ...prev, [field]: value }));
    } else {
      setScenarios(prev =>
        prev.map(scenario =>
          scenario.id === id ? { ...scenario, [field]: value } : scenario
        )
      );
    }
  }

  async function handleBlur(id, field) {
    if (id === 'new') return;
    try {
      const scenarioToUpdate = scenarios.find(s => s.id === id);
      const { error } = await supabase
        .from('scenarios')
        .update({ [field]: scenarioToUpdate[field] })
        .eq('id', id);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating scenario:', error.message);
      setError(`Failed to update scenario: ${error.message}`);
    }
  }

  async function handleAddNewScenario() {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .insert([newScenario])
        .select();

      if (error) throw error;

      setScenarios(prev => [data[0], ...prev]);
      setNewScenario({});
    } catch (error) {
      console.error('Error adding new scenario:', error.message);
      setError(`Failed to add new scenario: ${error.message}`);
    }
  }

  const columns = [
    { name: 'scenarioid', label: 'Scenario ID', width: 100 },
    { name: 'order', label: 'Order', width: 70 },
    { name: 'category', label: 'Category', width: 150 },
    { name: 'diagnosiscategory', label: 'Diagnosis Category', width: 150 },
    { name: 'title', label: 'Title', width: 200 },
    { name: 'patientreport', label: 'Patient Report', width: 300 },
    { name: 'status', label: 'Status', width: 100 },
    { name: 'question', label: 'Question', width: 300 },
    { name: 'option1', label: 'Option 1', width: 200 },
    { name: 'option2', label: 'Option 2', width: 200 },
    { name: 'option3', label: 'Option 3', width: 200 },
    { name: 'option4', label: 'Option 4', width: 200 },
    { name: 'explanation1', label: 'Explanation 1', width: 400 },
    { name: 'explanation2', label: 'Explanation 2', width: 400 },
    { name: 'explanation3', label: 'Explanation 3', width: 400 },
    { name: 'explanation4', label: 'Explanation 4', width: 400 },
    { name: 'answer', label: 'Answer', width: 70 },
    { name: 'fulltext', label: 'Full Text', width: 300 },
    { name: 'image', label: 'Image', width: 150 },
    { name: 'dateadded', label: 'Date Added', width: 120 },
    { name: 'tags', label: 'Tags', width: 150 },
    { name: 'meds', label: 'Medications', width: 200 },
    { name: 'exam', label: 'Exam', width: 200 },
    { name: 'physicalexam', label: 'Physical Exam', width: 200 },
    { name: 'comments', label: 'Comments', width: 300 }
  ];

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="datasheet-view">
      <h1>Scenarios Datasheet</h1>
      <div className="view-toggle">
        <Link to="/" className="home-link">Switch to Home View</Link>
      </div>
      <div className="datasheet-container">
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                {columns.map(column => (
                  <th key={column.name} style={{ minWidth: column.width }}>{column.label}</th>
                ))}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {columns.map(column => (
                  <td key={column.name}>
                    <input
                      type="text"
                      value={newScenario[column.name] || ''}
                      onChange={(e) => handleInputChange(e, 'new', column.name)}
                      placeholder={`New ${column.label}`}
                      style={{ width: column.width - 10 }}
                    />
                  </td>
                ))}
                <td>
                  <button onClick={handleAddNewScenario}>Add</button>
                </td>
              </tr>
              {scenarios.map(scenario => (
                <tr key={scenario.id}>
                  {columns.map(column => (
                    <td key={`${scenario.id}-${column.name}`}>
                      <textarea
                        value={scenario[column.name] || ''}
                        onChange={(e) => handleInputChange(e, scenario.id, column.name)}
                        onBlur={() => handleBlur(scenario.id, column.name)}
                        style={{ width: column.width - 10, minHeight: '30px' }}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DatasheetView;