import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import './HomeScreen.css';

function HomeScreen() {
  const [scenarios, setScenarios] = useState([]);
  const [currentScenario, setCurrentScenario] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('All Statuses');
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentResultIndex, setCurrentResultIndex] = useState(0);
  const [updatedStatus, setUpdatedStatus] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFullName, setUserFullName] = useState('');
  const navigate = useNavigate();

  const largeTextFields = ['patientreport', 'question', 'option1', 'option2', 'option3', 'option4', 'explanation1', 'explanation2', 'explanation3', 'explanation4', 'fulltext', 'tags', 'meds', 'exam', 'physicalexam', 'comments'];
  const mediumTextFields = ['image'];
  const fieldsToRemove = ['p', 'r', 'bp', 'spo2', 'bg'];

  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    const fullName = localStorage.getItem('userFullName');
    if (email && fullName) {
      setUserEmail(email);
      setUserFullName(fullName);
    } else {
      navigate('/');
    }
    fetchScenarios();
    fetchStatuses();
  }, [navigate]);

  useEffect(() => {
    if (currentScenario) {
      setUpdatedStatus(currentScenario.status || '');
    }
  }, [currentScenario]);

  const getFieldLabel = (key) => {
    const labels = {
      patientreport: 'Patient Report',
      question: 'Question',
      option1: 'Option 1',
      option2: 'Option 2',
      option3: 'Option 3',
      option4: 'Option 4',
      explanation1: 'Explanation 1',
      explanation2: 'Explanation 2',
      explanation3: 'Explanation 3',
      explanation4: 'Explanation 4',
      fulltext: 'Full Text',
      tags: 'Tags',
      meds: 'Medications',
      exam: 'Exam',
      physicalexam: 'Physical Exam',
      comments: 'Comments',
      scenarioid: 'Scenario ID',
      order: 'Order',
      category: 'Category',
      diagnosiscategory: 'Diagnosis Category',
      title: 'Title',
      status: 'Status',
      answer: 'Answer',
      image: 'Image',
      dateadded: 'Date Added'
    };
    return labels[key] || key.charAt(0).toUpperCase() + key.slice(1);
  };

  async function fetchScenarios(status = 'All Statuses', search = '') {
    try {
      let query = supabase.from('scenarios').select('*');
      
      if (status !== 'All Statuses') {
        query = query.eq('status', status);
      }
      
      if (search) {
        query = query.or(
          `title.ilike.%${search}%,` +
          `patientreport.ilike.%${search}%,` +
          `question.ilike.%${search}%,` +
          `scenarioid.eq.${parseInt(search) || 0}`
        );
      }
      
      query = query.order('scenarioid', { ascending: true }).order('order', { ascending: true });
      
      const { data, error } = await query;
      if (error) throw error;
      setScenarios(data);
      setSearchResults(data);
      setCurrentResultIndex(0);
      if (data.length > 0) setCurrentScenario(data[0]);
    } catch (error) {
      console.error('Error fetching scenarios:', error.message);
      setError(`Failed to fetch scenarios: ${error.message}`);
    }
  }

  async function fetchStatuses() {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .select('status')
        .not('status', 'is', null);
      if (error) throw error;
      const uniqueStatuses = ['All Statuses', ...new Set(data.map(item => item.status))];
      setStatuses(uniqueStatuses);
    } catch (error) {
      console.error('Error fetching statuses:', error.message);
      setError(`Failed to fetch statuses: ${error.message}`);
    }
  }

  function handleStatusChange(e) {
    const status = e.target.value;
    setSelectedStatus(status);
    fetchScenarios(status, searchTerm);
  }

  function handleScenarioChange(e) {
    const selectedScenario = scenarios.find(s => s.id.toString() === e.target.value);
    setCurrentScenario(selectedScenario);
    setEditMode(false);
    setIsAddingQuestion(false);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setCurrentScenario(prev => ({ ...prev, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (isAddingQuestion) {
      saveNewQuestion();
    } else {
      updateScenario(currentScenario);
    }
  }

  async function logAudit(action, details) {
    try {
      const { error } = await supabase
        .from('audit_log')
        .insert({ user_email: userEmail, action, details });
      if (error) throw error;
    } catch (error) {
      console.error('Error logging audit:', error.message);
    }
  }

  async function updateScenario(updatedScenario) {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .update(updatedScenario)
        .eq('id', updatedScenario.id)
        .select();
      if (error) throw error;
      setCurrentScenario(data[0]);
      setEditMode(false);
      fetchScenarios(selectedStatus, searchTerm);
      logAudit('update_scenario', `Updated scenario ${updatedScenario.id}`);
    } catch (error) {
      console.error('Error updating scenario:', error.message);
      setError(`Failed to update scenario: ${error.message}`);
    }
  }

  function handleAddQuestion() {
    if (!currentScenario) return;

    const newScenarioId = currentScenario.scenarioid;
    const newOrder = Math.max(...scenarios.filter(s => s.scenarioid === newScenarioId).map(s => s.order)) + 1;

    const newQuestion = {
      ...currentScenario,
      id: null,
      scenarioid: newScenarioId,
      order: newOrder,
      category: currentScenario.category,
      diagnosiscategory: currentScenario.diagnosiscategory,
      title: currentScenario.title,
      patientreport: currentScenario.patientreport,
      status: 'New',
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      explanation1: '',
      explanation2: '',
      explanation3: '',
      explanation4: '',
      answer: '',
      fulltext: '',
      image: '',
      dateadded: new Date().toISOString().split('T')[0],
      tags: '',
      meds: '',
      exam: '',
      physicalexam: '',
      comments: ''
    };

    setCurrentScenario(newQuestion);
    setIsAddingQuestion(true);
    setEditMode(true);
  }

  async function saveNewQuestion() {
    try {
      const { data, error } = await supabase
        .from('scenarios')
        .insert(currentScenario)
        .select();

      if (error) throw error;

      setScenarios([...scenarios, data[0]]);
      setCurrentScenario(data[0]);
      setIsAddingQuestion(false);
      setEditMode(false);
      fetchScenarios(selectedStatus, searchTerm);
      logAudit('add_question', `Added new question to scenario ${data[0].scenarioid}`);
    } catch (error) {
      console.error('Error adding new question:', error.message);
      setError(`Failed to add new question: ${error.message}`);
    }
  }

  function getExplanationStyle(key) {
    if (!currentScenario || !currentScenario.answer) return '';

    const explanationNumber = key.replace('explanation', '');
    if (explanationNumber === String(currentScenario.answer)) {
      return 'explanation-correct';
    } else {
      return 'explanation-incorrect';
    }
  }

  function handleSearch(e) {
    e.preventDefault();
    const sanitizedSearch = searchTerm.replace(/[%;]/g, '');
    fetchScenarios(selectedStatus, sanitizedSearch);
  }

  function handleNextResult() {
    if (currentResultIndex < searchResults.length - 1) {
      const nextIndex = currentResultIndex + 1;
      setCurrentResultIndex(nextIndex);
      setCurrentScenario(searchResults[nextIndex]);
    }
  }

  async function handleStatusUpdate() {
    if (!currentScenario) return;

    try {
      const now = new Date().toISOString();
      const statusChangeComment = `Status changed to ${updatedStatus} by ${userFullName} (${userEmail}) on ${now}`;
      const updatedComments = currentScenario.comments
        ? `${currentScenario.comments}\n${statusChangeComment}`
        : statusChangeComment;

      const { data, error } = await supabase
        .from('scenarios')
        .update({ 
          status: updatedStatus,
          comments: updatedComments
        })
        .eq('id', currentScenario.id)
        .select();

      if (error) throw error;

      setCurrentScenario(data[0]);
      fetchScenarios(selectedStatus, searchTerm);
      logAudit('update_status', `Updated status for scenario ${currentScenario.id} to ${updatedStatus}`);
    } catch (error) {
      console.error('Error updating status:', error.message);
      setError(`Failed to update status: ${error.message}`);
    }
  }

  function handlePrevious() {
    const currentIndex = scenarios.findIndex(s => s.id === currentScenario.id);
    if (currentIndex > 0) {
      setCurrentScenario(scenarios[currentIndex - 1]);
      setIsAddingQuestion(false);
      setEditMode(false);
    }
  }

  function handleNext() {
    const currentIndex = scenarios.findIndex(s => s.id === currentScenario.id);
    if (currentIndex < scenarios.length - 1) {
      setCurrentScenario(scenarios[currentIndex + 1]);
      setIsAddingQuestion(false);
      setEditMode(false);
    }
  }

  function toggleEditMode() {
    setEditMode(!editMode);
    if (editMode) {
      // If we're exiting edit mode, update the scenario
      updateScenario(currentScenario);
    }
  }

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="home-screen">
      <h1>Question Review Form</h1>
      
      <div className="user-info">
        <p>Welcome, {userFullName}</p>
        <button onClick={() => { 
          localStorage.removeItem('userEmail');
          localStorage.removeItem('userFullName');
          navigate('/');
        }}>Logout</button>
      </div>

      <div className="view-toggle">
        <Link to="/datasheet" className="datasheet-link">Switch to Datasheet View</Link>
      </div>
      
      {currentScenario && (
        <div className="current-status">
          Current Status: <span className="status-value">{currentScenario.status || 'No Status'}</span>
        </div>
      )}

      <div className="controls">
        <div className="form-group">
          <label>Filter by Status:</label>
          <select onChange={handleStatusChange} value={selectedStatus}>
            {statuses.map(status => (
              <option key={status} value={status}>{status}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Select Question:</label>
          <select onChange={handleScenarioChange} value={currentScenario?.id}>
            {scenarios.map(scenario => (
              <option key={scenario.id} value={scenario.id}>
                {`${scenario.scenarioid}-${scenario.order}`}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="search-container">
        <form onSubmit={handleSearch}>
          <input
            type="text"
            placeholder="Search by scenarioId, title, patient report, or question"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button type="submit">Search</button>
        </form>
        {searchResults.length > 0 && (
          <div className="search-results">
            <p>Showing result {currentResultIndex + 1} of {searchResults.length}</p>
            {currentResultIndex < searchResults.length - 1 && (
              <button onClick={handleNextResult}>Next Result</button>
            )}
          </div>
        )}
      </div>

      <div className="navigation">
        <button onClick={handlePrevious} disabled={scenarios.indexOf(currentScenario) === 0}>Previous</button>
        <button onClick={handleNext} disabled={scenarios.indexOf(currentScenario) === scenarios.length - 1}>Next</button>
      </div>

      {currentScenario && (
        <div id="scenarioData" className="scenario-details">
          <h2>{isAddingQuestion ? 'Add New Question' : 'Scenario Details'}</h2>
          <button onClick={toggleEditMode}>{editMode ? 'Cancel Edit' : 'Edit'}</button>
          <form onSubmit={handleSubmit}>
            {Object.entries(currentScenario).map(([key, value]) => {
              if (!fieldsToRemove.includes(key)) {
                return (
                  <div key={key} className={`field-group ${largeTextFields.includes(key) ? 'large-field' : ''} ${mediumTextFields.includes(key) ? 'medium-field' : ''}`}>
                    <label>{getFieldLabel(key)}:</label>
                    {largeTextFields.includes(key) || mediumTextFields.includes(key) ? (
                      <textarea
                        name={key}
                        value={value || ''}
                        onChange={handleInputChange}
                        readOnly={!editMode && !isAddingQuestion}
                        className={`${largeTextFields.includes(key) ? "large-text-field" : "medium-text-field"} ${
                          key.startsWith('explanation') ? getExplanationStyle(key) : ''
                        }`}
                      />
                    ) : (
                      <input
                      type={key === 'dateadded' ? 'date' : 'text'}
                        name={key}
                        value={value || ''}
                        onChange={handleInputChange}
                        readOnly={(!editMode && !isAddingQuestion) || key === 'id'}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
            {(editMode || isAddingQuestion) && (
              <button type="submit" className="save-button">
                {isAddingQuestion ? 'Add Question' : 'Save Changes'}
              </button>
            )}
          </form>
        </div>
      )}

      <div className="status-update">
        <label>Update Status:</label>
        <select 
          value={updatedStatus} 
          onChange={(e) => setUpdatedStatus(e.target.value)}
        >
          {statuses.filter(status => status !== 'All Statuses').map(status => (
            <option key={status} value={status}>{status}</option>
          ))}
        </select>
        <button className="update-status-button" onClick={handleStatusUpdate}>Update Status</button>
      </div>

      <button className="add-question-button" onClick={handleAddQuestion}>Add a Question</button>
    </div>
  );
}

export default HomeScreen